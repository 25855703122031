.subscriptionPlanNewCss {
    padding-bottom: 1rem;

    .borderBottom {
        border-bottom: 1px solid rgb(217, 215, 215);
    }

    .col1 {
        margin: 1rem 2rem 0rem 2rem;
        padding-bottom: 1rem;
        // border-bottom: 1px solid rgb(217, 215, 215);
    }

    .selectTitle {
        font-weight: 600;
        font-size: 20px;
        color: #000;
    }

    .para1 {
        font-size: 18px;
        color: #000;
        margin-top: 2px;
        // margin-bottom: 1.5rem;
    }

    .custom_gradient {
        background: linear-gradient(to top, #E2F6FE, 60%, white);
    }

    .learnMoreSpan {
        // color: rgb(21, 182, 236);
        color: #23599C;

        a {
            color: #23599C;
            font-weight: 400;

            &:hover {
                color: lightblue;
            }
        }
    }

    .go_back_btn_desktop {
        cursor: pointer;
    }

    .btn_logout {
        // border: 2px solid grey;
        border: 1px solid #9D9D9D;

        &:hover {
            border: 1px solid lightgrey;
        }
    }

    .box_1 {
        display: flex;
        align-items: center;
    }

    .priceDivMain {
        padding: 0;
        border: 0;
        font-size: 100%;
        font-family: "Gotham Pro", Arial, serif;
        color: rgba(0, 0, 0, .6);
        vertical-align: baseline;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: end;
        -webkit-box-flex: 1;
        flex-grow: 1;
        position: relative;
        // width: 228px;
        width: 250px;
        height: 47px;
        margin: 30px auto 40px;
        // margin-bottom: 30px;
    }

    .mobViewPrice {
        margin-bottom: 40px;
    }

    .priceOuter {
        border: 0;
        font-size: 100%;
        font-family: "Gotham Pro", Arial, serif;
        color: rgba(0, 0, 0, .6);
        vertical-align: baseline;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 950px;
        display: flex;
        position: relative;
        z-index: 5;
        margin: 0 10px;
        padding: 0;
        width: 100%;
        border-radius: 60px;
        background: 0 0;
    }

    .priceSecondDiv {
        margin: 0;
        padding: 0;
        font-size: 100%;
        font-family: "Gotham Pro", Arial, serif;
        color: rgba(0, 0, 0, .6);
        vertical-align: baseline;
        flex-shrink: 0;
        -webkit-box-ordinal-group: 3;
        order: 2;
        width: 100%;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-pack: justify;
        justify-content: space-between;
        height: 47px;
        // border: 1px solid rgb(0, 0, 0);
        // border-radius: 60px;
        -webkit-box-align: center;
        align-items: center;
    }

    .yearDiv {
        vertical-align: baseline;
        text-decoration: none;
        transition: .2s;
        padding: 0;
        margin: 0;
        border: 0;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        outline: 0;
        // font: 400 18px/1 Axiforma, Arial, serif;
        text-align: center;
        -webkit-box-pack: center;
        justify-content: center;
        width: 150px;
        height: 47px;
        // border-radius: 60px;
        color: #000000;
        font-weight: 500;
        cursor: pointer;
        transition: 0.3s ease-in;
        border-bottom: 4px solid;
        border-color: transparent;
        font-family: Montserrat, sans-serif;
        font-size: 20px;
    }

    .monthDiv {
        vertical-align: baseline;
        text-decoration: none;
        transition: .2s;
        padding: 0;
        margin: 0;
        border-radius: 10px;
        border: 0;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        background: 0 0;
        outline: 0;
        width: 150px;
        height: 47px;
        color: rgb(0, 0, 0);
        font: 400 15px/1 Axiforma, Arial, serif;
        text-align: center;
        -webkit-box-pack: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        font-family: Roboto, sans-serif;
    }

    .active {
        width: 220px;
        height: 47px;
        // border-radius: 60px;
        // color: #ffffff;
        // background-color: rgb(0, 0, 0);
        font-weight: 500;
        padding: 0;
        border-color: #d23f99;
    }

    // .col{
    //     // background-color: aqua;
    // }
    .containerTable {
        // margin: 0rem 2%;
        // box-shadow: 0 0 5px hsl(0deg 0% 46% / 50%);
        // box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        box-shadow: 0 3px 20px #767b9f26;
        border: 3px solid #EFEFF5;
        // border: none !important;
        // border-right: 1px solid #EFEFF5 !important;

        // border-radius: 6px;
        margin-top: 5rem;
        // width: 100%;
        margin-inline: auto;
        width: fit-content;
        // padding: 25px;
    }

    .tableCols .tableCellCol:first-child {
        border-bottom: 3px solid #EFEFF5;

    }

    .tableCellCol:has(.feature_title) {

        border-left: none;



    }

    .tableCols:first-child .tableCellCol:has(.feature_title) {

        border-left: 1px solid #EFEFF5;

    }


    .setMarginNew {
        margin: 0rem 2% !important;
    }

    .timerDiv {
        position: absolute;
        top: -90px;
    }

    .timCol {
        border: 1px solid #706f6f;
    }

    // .tableCols{
    //     display: table-column;
    // }
    .tableCellCol {
        // border: 1px solid #e0e0e0;
        border: 1px solid #EFEFF5;
        // border-inline: 1px solid #EFEFF5;

        // border: 1px solid #EFEFF5;
        border-right: none !important;
        // #eaeaea
        border-collapse: collapse;
    }

    .tableCellCol.special_cell {
        border-top: none;
        // border-bottom: 3px solid #EFEFF5;
    }

    .offerExp {
        font-weight: 600;
        color: #000;
    }

    .pricee {
        font-weight: 600;
        font-size: 16px;
        color: #000;
        margin-top: 0.5rem;
    }

    .priceFEa {
        font-weight: 600;
        font-size: 16px;
        color: #000;
    }

    .feature_title {
        font-weight: 600;
        font-size: 15px;
        // font-size: 15px;
        color: #333333;
        // min-height: 32px;
        min-height: 50px;
        display: flex;
        align-items: center;
        margin-inline: -15px;
        padding-inline: 25px;
        border-bottom: 1px solid #EFEFF5;
        font-family: Inter, sans-serif;
    }

    .features_list {
        list-style: none;
        padding: 0;

        li {
            color: #333333;
            font-weight: 500;
            display: flex;
            align-items: center;
            // min-height: 25px;
            min-height: 40px;
            // font-size: 14px;
            font-size: 14px;
            margin-inline: -15px;
            padding-inline: 25px;
            border-bottom: 1px solid #EFEFF5;
            border-right: 1px solid #EFEFF5;

            font-family: Inter, sans-serif;

            &:last-child {
                border-bottom: none;
            }
        }

        .circle_info_tooltip {
            opacity: 0.3;

            &:hover {
                opacity: 1;
            }
        }
    }

    .tableCols:last-child .features_list li {
        border-right: none !important;

    }

    .feature_values {
        font-weight: 500;
        // font-size: 13px;
        font-size: 14px;
        color: #333333;
    }

    .divCont1 {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        // padding-bottom: 20px;
    }

    .priceDivsCol {
        height: auto;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
    }

    .priceTitle {
        font-size: 18px;
        font-weight: 600;
        margin-top: .3rem;
        margin-bottom: .5rem;
        // margin-top: 2rem;
        // margin-bottom: 1rem;
        color: #000;
        width: 100%;
        text-align: center;
    }

    .priceDesc {
        text-align: center;
        margin-bottom: 1rem;
        // min-height: 70px;
        height: 45px;
        font-size: 14px;
    }

    .monthFees {
        font-size: 20px;
        font-weight: 600;
        color: #000;
    }

    .monthtit {
        margin-bottom: 0.7rem;
    }

    .audDet {
        margin-top: -2px;
    }

    .h5Billed {
        border: 1px solid #13d9ab;
        padding: 2px 10px;
        font-weight: 500;
        color: #000;
        margin-top: 8px;
    }

    .integDiv {
        height: 100%;
        // padding: 15px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .integTitle {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        text-align: left !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .integDesc {
        margin-top: "6px"
    }

    .percDiv {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
    }

    .newDew {
        margin-top: 2rem;
    }

    .butonDiv {
        // height: 100%;
        // margin-top: 2rem;
        margin-top: 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        // bottom: 10px;
        bottom: 15px;
    }

    .popularCss {
        //  border: 2px solid #13d9ab;
        // border: 2px solid #d23f99;
        // background-color: #fefafd;
        border-collapse: collapse;

    }

    .popular_container {
        // color: #010101;
        // background-color: #FFD3A1;
        // font-weight: 600;
        // text-align: center;
        // padding: 5px;
        // border-collapse: collapse;
        // width: 70%;
        // margin: auto;
        // font-size: 16px;
        // border-radius: 50px;
        // margin-block: 6px;
        padding-block: 14px;
    }

    .popularName {
        color: #010101;
        background-color: #FFD3A1;
        font-weight: 600;
        text-align: center;
        padding: 5px;
        border-collapse: collapse;
        width: 60%;
        margin: auto;
        // font-size: 16px;
        font-size: 13px;
        border-radius: 50px;
        // margin-block: 6px;
        text-transform: uppercase;
    }

    // .popularName {
    //     color: #fff;
    //     background-color: #d23f99;
    //     font-weight: 600;
    //     width: 100%;
    //     text-align: center;
    //     padding: 5px;
    //     border-collapse: collapse;
    //     width: 101%;
    //     margin: -1px;
    //     font-size: 16px;
    // }

    .triangleDiv {
        display: flex;
        justify-content: center;
        height: 12px;
    }

    .emptyDiv {
        // height: 46px;
        height: 59px;
    }

    .textLine {
        font-weight: 400;
        color: #000;
        font-size: 15px
    }

    .loadingDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        min-height: 100vh;
        min-width: 100vw;
    }

    .selectedCard {
        background-color: #ececec;
    }

    .timTitle {
        font-weight: 500;
        color: #c20505;
        font-size: 18px;
        letter-spacing: 1px;
    }

    .timeRow {
        margin-top: 10px;
    }

    .pTag {
        margin-top: 2px;
    }

    .timerDiv {
        width: 100%;
        padding: 0% 10%;
    }

    .strikedd {
        font-size: 15px;
        font-weight: 500;
    }

    .integDesc {
        max-height: 120px;
        overflow-y: auto;
    }

    .fontLight {
        color: #c3c3c3
    }

    .ibutton {
        color: #757575;
        border: 1px solid #757575;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        padding: 1px;
        background-color: transparent;
        cursor: pointer;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
    }

    // mob_tab changes
    .mob_tab_container {
        // margin: 25px 0;
        // margin: 25px 0 10px 0;
        margin: 0 0 10px 0;

        .mob_tabTitle_container {
            border-bottom: 1px solid #dee3e6;

            .mob_tab_title {
                // font-size: 20px;
                font-size: 18px;
                font-weight: 600;
                text-align: center;
                color: #646777;
                padding: 7px;
            }

            .tab_active {
                // border-bottom: 5px solid #d23f99;
                border-bottom: 3px solid #181818;
                color: #d23f99;
            }
        }
    }

    .card_container.popular_container {
        position: relative;

        .popular_item {
            position: absolute;
            top: -14px;
            // background: #d23f99;
            // color: #fff;
            background: #ffd3a1;
            font-weight: 600;
            color: #010101;
            padding: 3px 20px;
            border-radius: 20px;
            font-size: 16px;
        }
    }

    .card_container.selected_container {
        // border: 3px solid #d23f99 !important;
        background-color: #ececec;
    }

    .card_container {
        // border: 3px solid #d23f99;
        border: 3px solid #dee3e6;
        border-radius: 25px;
        padding: 25px 25px 10px 25px;
        // margin: 20px 0;
        // margin: 10px 0;
        margin: 15px 0 10px 0;


        .plan_card_header {
            display: flex;

            .plan_title {
                display: flex;
                align-items: center;
            }

            .plan_title h3,
            .plan_price {
                // font-size: 24px;
                font-size: 20px;
                font-weight: 600;
                color: #000;
            }

            input[type="checkbox"] {
                width: 20px;
                height: 20px;
                accent-color: #d23f99;
            }
        }

        .plan_card_body {
            // font-size: 18px;
            font-size: 16px;
            color: #646777;
            margin: 10px 0;
        }

        .plan_card_footer {
            p {
                color: #000;
                font-weight: 500;
                font-size: 16px;
            }

            .mob_feature_container {
                list-style: none;

                // li.on:before {
                //     content: '✔';
                //     font-weight: bold;
                //     margin: 0 10px 0 -10px;
                //     // color: #d23f99;
                //     color: #4db055;
                // }

                // li.off:before {
                //     // content: '🗙';
                //     content: '❌';
                //     font-weight: bold;
                //     margin: 0 10px 0 -10px;
                //     // color: #d23f99;
                //     color: #d15353;
                // }

                li {
                    color: #646777;
                    font-size: 16px;
                    display: flex;
                    align-items: start;
                }
            }
        }
    }

    .proceed_button_container {
        width: 100%;
        background: white;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        // position: fixed;
        // bottom: 0;
        // left: 0;
    }


    @media only screen and (max-width: 800px) {
        .timerDiv {
            width: 100%;
            padding: 0% 0%;
        }

        .monthFees {
            font-size: 20px;
            font-weight: 600;
            color: #000;
        }

        .pcView {
            display: none;
        }

        .mobileView {
            display: block;
        }

        .mobileViewP1 {
            text-align: center;
            font-size: 17px;
            margin-top: 2rem;
        }

        .contMob {
            margin-top: 1rem !important;
            margin-bottom: 4rem !important;
        }

        .marginTopMob {
            margin-top: 8rem !important;
        }

        #cloudBasedP {
            display: none;
        }

        .priceTitle {
            font-size: 18px;
            font-weight: 600;
            margin-top: 2rem;
            margin-bottom: 0rem;
            color: #000;
            width: 100%;
            text-align: center;
        }

        .audDet {
            text-align: center;
        }

        .butonDiv {
            // height: 100%;
            margin-top: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: inherit;
            // bottom: 5px;
        }


        .priceSecondDiv {
            margin: 0;
            padding: 0;
            font-size: 100%;
            font-family: "Gotham Pro", Arial, serif;
            color: rgba(0, 0, 0, .6);
            vertical-align: baseline;
            flex-shrink: 0;
            -webkit-box-ordinal-group: 3;
            order: 2;
            width: 100%;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            -webkit-box-pack: justify;
            justify-content: space-between;
            height: 47px;
            border: 1px solid rgb(0, 0, 0);
            border-radius: 60px;
            -webkit-box-align: center;
            align-items: center;
        }

        .yearDiv {
            vertical-align: baseline;
            text-decoration: none;
            transition: .2s;
            padding: 0;
            margin: 0;
            border: 0;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            outline: 0;
            // font: 400 18px/1 Axiforma, Arial, serif;
            font: 400 15px/1 Axiforma, Arial, serif;
            text-align: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 150px;
            height: 47px;
            border-radius: 60px;
            color: #000000;
            font-weight: 600;
            cursor: pointer;
            transition: 0.3s ease-in;
            // border-bottom: 4px solid;
            // border-color: transparent;
            font-family: Roboto, sans-serif;
        }

        .active {
            width: 220px;
            height: 47px;
            border-radius: 60px;
            color: #ffffff;
            background-color: rgb(0, 0, 0);
            font-weight: 600;
            padding: 0;
            // border-color: #d23f99;
        }

        .feature_title {
            border-bottom: none;
            min-height: 30px;
        }

        .feature_title:not(:first-child) {
            margin-top: 10px;
        }


    }

    @media only screen and (min-width:800px) {
        .mobileView {
            display: none;
        }

        .pcView {
            display: block;
        }

        // .priceTitle {
        //     font-size: 18px !important;
        //     line-height: 24px !important;
        // }
    }

    @media only screen and (max-width: 1000px) {
        .containerTable {
            margin: 0rem 1%;
        }

        .monthFees {
            font-size: 24px;
        }

        .priceDesc {
            font-size: 14px;
        }

        .priceTitle {
            font-size: 18px !important;
            line-height: 23px !important;
        }

    }

    @media only screen and (min-width: 1200px) {
        .containerTable {
            margin: 0rem 3%;
        }

        .priceTitle {
            // font-size: 14px;
            // font-size: 16px;
            font-size: 17px;
        }

        .timerDiv {
            width: 100%;
            padding: 0% 10%;
        }

        .monthFees {
            font-size: 22px;
        }

        .priceDesc {
            font-size: 14px;
        }

    }

    @media only screen and (min-width: 1300px) {
        .containerTable {
            margin: 0rem 7%;
        }

        .priceTitle {
            // font-size: 16px;
            font-size: 17px;
        }

        .timerDiv {
            width: 100%;
            padding: 0% 15%;
        }

        .monthFees {
            font-size: 24px;
        }

        .priceDesc {
            font-size: 14px;
        }
    }

    @media only screen and (min-width: 1400px) {
        .containerTable {
            margin: 0rem 7%;
        }

        .priceTitle {
            font-size: 18px;
        }

        .timerDiv {
            width: 100%;
            padding: 0% 22%;
        }

        .monthFees {
            // font-size: 30px;
            // font-size: 28px;
            font-size: 25px;
        }

        .priceDesc {
            font-size: 14px;
        }
    }

    #cloudBasedP {
        color: #757575;
        border: 1px solid #757575;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        padding: 1px;
        background-color: transparent;
        cursor: pointer;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
    }

    .ibutton {
        color: #757575;
        border: 1px solid #757575;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        padding: 1px;
        background-color: transparent;
        cursor: pointer;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
    }

    // .popDiv{
    //     background-color: #fff;
    //     color: #7c4efc;
    //     text-align: center;
    // }
}

@media (min-width:992px) {
    .subscriptionPlanNewCss {
        padding-bottom: 4rem;
    }
}


@media (max-width:576px) {
    .priceDivMain {
        width: auto !important;
    }

}

.popoverStyle {
    max-width: 110px !important;

    .popover {
        // background-color: red;
        width: 110px !important;
    }
}

.popoverInfoStyle {
    .popover {
        background-color: #000000b5;
    }
}