.main_class {

    .table_class {

        .table>tbody>tr>td {
            padding: 0.5rem !important;
            vertical-align: unset !important;
        }

        .table>thead>tr>th {
            padding: 1rem !important;
        }
    }
}