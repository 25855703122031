

@media (min-width: 576px) and(max-width: 767px) {
    .input_otp_field{             
        margin: 0px 10px;
    }
}


@media (min-width: 768px) and(max-width: 991px) {
    .input_otp_field{            
        margin: 0px 20px;
    }
}


@media (min-width: 992px) and(max-width: 1199px) {
    .input_otp_field{            
        margin: 0px 15px;
    }

}


@media (min-width: 1200px) {

    .input_otp_field{            
        margin: 0px 8px;
    }
}