.main_div {

    .imageLink {
        text-decoration: underline;
    }

    .imageLink:hover {
        color: #008FE2;
    }

    // .input_box {
    //     background-color: aqua !important;
    // }
    // .table>tbody>tr>td {
    //     padding: 1rem !important;
    //     vertical-align: top !important;
    // }
}