.info-text{
    font-weight: bold;
    font-size: 15px;
    padding: 15px 0px;
}

.info-result {
    font-size: 15px;
    padding: 15px 0px;
}

.error-text-color{
    color: #ff5370;
}

.autocomplete-dropdown-container{
    background-color: #fff;
    position: absolute !important;
    z-index: 1000;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    width: 95% !important;
}

.suggestion-itemm--active{
    background-color: #fafafa;
}

.loaderr {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #327ebc;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }