.custom-dot-list-class {
    position: relative !important;
    margin-top: 15px;
    cursor: pointer;
}

.active-span-dot {
    width: 12px;
    height: 8px;
    display: flex;
    background: rgb(52, 135, 161);
    margin-right: 2px;
    margin-left: 2px;
    opacity: 1;
    border-radius: 15px;
}

.inactive-span-dot {
    width: 10px;
    height: 8px;
    display: flex;
    background: rgb(52, 135, 161);
    margin-right: 2px;
    margin-left: 2px;
    opacity: 0.4;
    border-radius: 15px;
}