.tab_page_styles_cus {

    display: flex;
    gap: 0.5rem;

    .tab_style_cus {
        margin: 0;
        padding: 0;
        font-size: 12px;
        // margin: 0 0.2rem;
        max-width: fit-content;
        // padding: 0 0.4rem;
        // border-right: 0.15rem solid #646777;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
    }

    .divider {
        margin: 0.2rem 0;
        border-left: 0.15rem solid #646777;
    }

    .tab_style_active_cus {
        color: #D23F99 !important;
        background-color: #fff !important;
        max-width: fit-content;
        // border-bottom: 0.15rem solid #D23F99;
        cursor: pointer;
    }
}