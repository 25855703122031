.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: #F6F6F6;
        // background-color: hsl(200, 20%, 80%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.skeleton_sm-round {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
}

.skeleton_rectangle {
    width: 100%;
    height: 200px;
    border: none;
}

.skeleton-text__body {
    width: 75%;
}

.skeleton-footer {
    width: 30%;
}