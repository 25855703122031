.cropper_modal_content {
  .modal-body {
      .crop_box_style {
          height: 300px !important;
          width: 100% !important;

          .cropper-container .cropper-bg {
              height: 300px !important;
              width: 100% !important;
          }
      }
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {

      .modal-body {
          .crop_box_style {
              height: 300px !important;
              width: 100% !important;

              .cropper-container .cropper-bg {
                  height: 300px !important;
                  width: 100% !important;
              }
          }
      }
  }
}