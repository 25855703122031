.store_qr_code_style {

    background: #fff;

    .qr_code_col {
        .qr_code_section {}
    }

    .box_shadow_style {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    }

    .title_style {
        font-size: 16px;
        color: #000;
    }

    .desc_style {
        font-size: 13px;
    }

    .logo_style {
        height: 5.6rem;
        width: 5.6rem;
    }

    @media screen and (min-width: 0px) and (max-width: 576px) {

        .logo_style {
            height: 7.6rem;
            width: 7.6rem;
            min-width: 7.6rem;
        }

        .title_style {
            font-size: 20px;
        }

        .desc_style {
            font-size: 16px;
        }
    }



}


.drop_box {

    position: relative !important;

    .download_qr_btn {
        background: '#d23f99' !important;
        color: 'white' !important;
        padding: "10px 40px" !important;
        cursor: "pointer" !important;
    }

    .dropdown_menu {
        border: "1px solid #ccc" !important;
        background-color: "#fff" !important;
        position: 'absolute' !important;
        top: "45px" !important;
        left: "-1px" !important;
        width: "100%" !important;
        // padding: 0px !important;
    }

    .dropdown_menu_option {
        padding: "1rem" !important;
        cursor: "pointer" !important;
        color: black !important;
    }

    .dropdown_menu_option:hover {
        background-color: #f2f2f2 !important;
    }

}


.contentClassName {
    .modal-body {
        .crop_box_style {
            height: 300px !important;
            width: 100% !important;

            .cropper-container .cropper-bg {
                height: 300px !important;
                width: 100% !important;
            }
        }
    }

    @media screen and (min-width: 0px) and (max-width: 576px) {

        .modal-body {
            .crop_box_style {
                height: 400px !important;
                width: 100% !important;

                .cropper-container .cropper-bg {
                    height: 400px !important;
                    width: 100% !important;
                }
            }
        }
    }
}