.toggle_button {

    .switch {
        position: relative;
        display: inline-block;
        width: 70px;
        height: 28px;
        // margin-bottom: 0.1rem !important;
        // margin-top: 0.5rem !important;


    }

    .switch input {
        display: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: silver !important;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .sliderDisabled {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: silver;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before,
    .sliderDisabled:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 4px;
        background-color: white !important;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked+.slider {
        background-color: #35B543 !important;
        // background-color: #45c7a2 !important;
    }

    .redslide {
        background-color: #45c7a2 !important;
    }

    input:checked+.redslide {
        background-color: red !important;
    }

    .opposite {
        background-color: #35B543 !important;
    }

    input:checked+.opposite {
        background-color: red !important;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px silver !important;
    }


    input:checked+.slider:before,
    input:checked+.sliderDisabled:before {
        -webkit-transform: translateX(27px);
        -ms-transform: translateX(27px);
        transform: translateX(27px);
    }

    /*------ ADDED CSS ---------*/
    .on {
        display: none;
    }

    .on {
        color: white;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 30%;
        font-size: 11px;
        font-family: Verdana, sans-serif;
    }

    .off {
        color: white;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 65%;
        font-size: 10px;
        font-family: Verdana, sans-serif;
    }

    input:checked+.slider .on,
    input:checked+.sliderDisabled .on {
        display: block;
    }

    .sliderDisabled .on {
        display: block !important;
    }

    input:checked+.slider .off,
    input:checked+.sliderDisabled .off {
        display: none !important;
    }

    .sliderDisabled .off {
        display: none;
    }

    /*--------- END --------*/

    /* Rounded sliders */
    .slider.round,
    .sliderDisabled.round {
        width: 80% !important;
        border-radius: 34px;
    }

    .slider.round:before,
    .sliderDisabled.round:before {
        border-radius: 50%;
    }
}