.meet_tabs .active{
  color: #fff !important;
  background-color: #327ebc !important;
}

.meet_tabs a.nav-link {
  border: 1px solid #dee2e6 !important;
  width: 9rem;
  text-align: center;
  border-radius: 4px !important;
  color: #000;
  cursor: pointer;
  font-size: 12px;
}

.meet_tabs li:first-child a {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}