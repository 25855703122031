.AddPrinterModal {

    .name_input_field {
        height: 41px !important;
    }

    .react-select__option--is-disabled {
        cursor: not-allowed
    }

    .react-select__control--is-disabled {
        background-color: #E9ECEF;
    }

    .react-select__control--is-disabled .react-select__indicators {
        display: none;
    }

    input:read-only {
        background-color: #E9ECEF;
    }

}

.printer_table_styles {
    .table--bordered {
        th {
            // background-color: #777b87 !important;
            // color: #fff !important;
            border: 1px solid #dee2e6;
            padding: 10px !important;
        }

        td {
            border: 1px solid #dee2e6;
            padding: 10px !important;
        }
    }
}