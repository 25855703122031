
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    display: none;
}

// #tipmobile:hover{
//     opacity: 0.5;
// }

textarea {
    height: -webkit-fill-available !important;
}

.qr_codePage_form_user_select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}


.QRbuttons {
    overflow: hidden;


    .lf {
        text-align: end;
        cursor: pointer;
    }

    .detailspn {
        .detailP {
            color: #43444e;
            margin-left: 1.9rem;
            margin-right: 1rem;
        }
    }

    .md {
        // display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
        cursor: pointer;

        .mditems {
            text-align: center;
            flex-shrink: 0;
            border: 1px solid black;
            width: auto;
            min-width: 15%;
            height: auto;
            position: relative;

            .btntitle {
                font-size: 13px;
                font-weight: 400;
                display: -webkit-inline-box;
                color: #59595A;
                max-width: 10ch;
                line-height: 1.1rem;
            }

            .iconsUp {
                margin-bottom: -2.8rem;
            }
        }
    }

    .md::-webkit-scrollbar {
        display: none;
    }

    .rg {
        text-align: unset;
        cursor: pointer;
    }
}

.qrcode_page {
    .qr_cog_style {
        cursor: pointer;
    }

    //fixed phoneVier styles

    .fixedPhone {
        position: fixed;
        top: 70px;
        right: 0;
    }


    .input_field_style {
        border: none !important;
    }

    .toggle_button_small {
        .switch {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 20px;
        }

        .switch input {
            display: none;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: silver;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        input:checked+.slider {
            background-color: #35B543 !important;
            // background-color: #45c7a2;
        }

        input:focus+.slider {
            box-shadow: 0 0 1px silver;
        }

        input:checked+.slider:before {
            -webkit-transform: translateX(20px);
            -ms-transform: translateX(20px);
            transform: translateX(20px);
        }

        /*------ ADDED CSS ---------*/
        .on {
            display: none;
        }

        .on {
            color: white;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 30%;
            font-size: 11px;
            font-family: Verdana, sans-serif;
        }

        .off {
            color: white;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 65%;
            font-size: 10px;
            font-family: Verdana, sans-serif;
        }

        input:checked+.slider .on {
            display: block;
        }

        input:checked+.slider .off {
            display: none;
        }

        /*--------- END --------*/

        /* Rounded sliders */
        .slider.round {
            border-radius: 34px;
        }

        .slider.round:before {
            border-radius: 50%;
        }
    }

    .mdi_icon_style {
        cursor: pointer;

        &:active,
        &:focus {
            outline: 0;
            border: none;
        }
    }

    input[disabled]:hover,
    select[disabled]:hover,
    .disabled:hover {
        cursor: not-allowed;
        opacity: 0.5;
    }
}


.qr_code_page {
    .mobile_screen {
        &::-webkit-scrollbar {
            display: none !important;
            width: 0px !important;
        }

        @media (max-width:480px) {
            border-radius: 30px !important;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        & {
            -ms-overflow-style: none !important;
            /* IE and Edge */
            scrollbar-width: none !important;
            /* Firefox */
        }
    }

    .spn {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }

    .button_effect {
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 2px 1px gray;
        }
    }
}

.drag_button_qr {
    opacity: .5;
}

.qr_options_btn_style {
    width: 0px;
    padding: 0px;
    border-right: 0;
    transition: width .1s, padding .1s;
}

.qr_options_btn_style:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.qr_button_sptr_style:hover .drag_button_qr {
    opacity: 1;
}

.qr_button_sptr_style:hover .qr_options_btn_style {
    width: 30px;
    padding: 3px;
    border-right: solid grey 1px;
}


.modal-change {

    h5 {
        font-size: 15px;
        font-weight: bold;
    }

    input[type=text],
    input[type=number] {
        background: transparent !important;
        background-color: transparent !important;
        width: 100%;
        padding: 5px 10px;
        height: 36px;
        border: 1px solid #cacaca;
        font-weight: 450 !important;
    }
}

#profileImageInitials {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #c7c7c7;
    background: #f3f7f8;
    font-size: 18px;
    color: #6b7281;
    text-align: center;
    line-height: 46px;
    margin: 2px 0;
}

.yt-embed-video {
    aspect-ratio: 16 / 9;
    width: 100%;
}

@media (max-width:480px) {
    .phoneview-reverse {
        flex-direction: column-reverse !important;
    }

    .phonecomp-mobile {
        height: 100% !important
    }

    .min-max-height-auto {
        max-height: auto !important;
        min-height: auto !important;
        height: auto !important;
    }

    .margin-0-480px {
        margin: 0px !important;
    }

    .space-between-480px {
        justify-content: space-between !important;
    }

    .padding-480px-10px {
        padding: 10px !important;
    }

    .btn-width-480px {
        width: 5.5rem !important;
    }

    .phone-w-30 {
        width: 30% !important;
    }

    .width-25p-mobile {
        width: 25% !important;
    }
}

@media (max-width: 1199px) {
    .height-mobile-300px {
        height: 300px !important;
    }
    .mobile-display-only {
        display: flex !important;
    }

    .phone-zoom-in-out {
        position: absolute;
        display: flex;
        // box-sizing: border-box;
        z-index: 556;
        top: 2vh;
        height: 40px;
        width: 40px;
        right:6vh;
        border-radius: 100px;
        box-shadow: rgba(84, 84, 84, 0.1) 0px 2px 4px 2px;
        opacity: 1;
        transform: scale(.9);
        cursor: pointer;
    }

    .phone-zoom{
        transform: scale(.82);
        right: 4vh;

    }

    

    .hide-480px {
        display: none !important;
    }
}

.padding-1rem-sm-2rem {
    padding: 2rem;
}

.w-100-sm-60 {
    width: 60% !important;
    min-width: 310px;
    max-width: 330px;
}

.business-logo-size {
    width: 7rem;
    height: 7rem;
}

.minisite-heading-text {
    font-size: 16px;
    font-weight: 700;
    color: black !important;
}

.minisite-sub-heading-text {
    font-size: 13px;
    font-weight: 400;
    color: #646777 !important;
}

.textarea-custom-h {
    height: 85px !important;
    min-height: 80px !important;
    max-height: 90px !important;
}

@media (max-width:480px) {
    .phone-font-size-16px {
        font-size: 16px !important;
    }

    .phone-font-size-18px {
        font-size: 18px !important;
    }

    .phone-font-size-20px {
        font-size: 20px !important;
    }

    .hide-phone-minisite {
        display: none;
    }

    .phone-btn-group-size-margin {
        width: 100%;
        margin-top: 10px;
    }

    .padding-1rem-sm-2rem {
        padding: 1rem;
    }

    .w-100-sm-60 {
        width: 100% !important
    }

    .business-logo-size {
        width: 8rem;
        height: 8rem;
    }

    .textarea-custom-h {
        height: 85px !important;
        min-height: 80px !important;
        max-height: 90px !important;
    }

    .minisite-heading-text {
        font-size: 18px;
    }

    .minisite-sub-heading-text {
        font-size: 15px;
    }
}

@media (max-width: 1199px) {

    .hide-phone-minisite {
        display: none !important;
    }

}

@media (min-width: 1200px) {
    .phoneWithFixedForDesktop {
        transition: all 0.5s ease-in-out;
        position: fixed;
        margin: 5px 0;
    }
}