.smartphone {
	position: relative;
	width: 302px;
	height: 600px;
	margin: auto;
	border: 2px rgb(148, 148, 148) solid;
	/* border-top-width: 60px;
	border-bottom-width: 60px; */
	border-radius: 40px;
}
.smartphoneheader {
	background-color: #3d90ab;
	height: 66px;
	width: 298px;
	border-top-left-radius: 38px;
	border-top-right-radius: 39px;
	margin-top: -1px;
}

.card-body {
	cursor: pointer;
}

.headertitle {
	color: white;
	margin: 0;
	position: absolute;
	top: 6%;
	left: 49%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	font-size: 18px;
}

.previewcontinuebtn {
	color: white;
	height: 36px;
	width: 95%;
	background-color: #3d90ab;
	margin: 0;
	position: absolute;
	top: 92%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border: none;
	border-radius: 6px;
	outline: none;
	cursor: pointer;
	font-size: 18px;
}
.card-header {
	padding-top: 0.4rem !important;
	padding-bottom: 0rem !important;
}
.imagecard {
	margin: 8px 0px;
	position: absolute;
	top: 43%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -75%);
	height: 175px;
	width: 100%;
	max-width: 200px;
	border-radius: 8px;
	display: block;
}
.imagecardimagesingle {
	margin: 0;
	position: absolute;
	top: 43%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	height: 175px;
	width: 100%;
	max-width: 200px;
	border-radius: 8px;
}
.imagecardimage {
	border-radius: 6px;
	height: 175px;
	width: 100%;
	max-width: 200px;
	display: block;
	object-fit: contain;
}

.dropcontainer {
	height: 250px;
	width: 400px;
	border: 1px solid rgb(182, 182, 182);
	border-radius: 6px;
	box-shadow: 0 1px 3px rgba(92, 92, 92, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.dropcontainer:hover {
	box-shadow: 0 14px 28px rgba(104, 104, 104, 0.1),
		0 10px 10px rgba(90, 90, 90, 0.15);
}

.singlebutton {
	background-color: transparent;
	cursor: pointer;
	display: block;
}
.singlebutton:hover {
	background-color: whitesmoke;
	cursor: pointer;
}
.disabledTrue {
	pointer-events: none;
	opacity: 0.7;
}

.singlebutton1 {
	background-color: transparent;
	cursor: pointer;
}
.singlebutton1:hover {
	background-color: whitesmoke;
	cursor: pointer;
}

.singlebutton2 {
	background-color: transparent;
	cursor: pointer;
}
.singlebutton2:hover {
	background-color: whitesmoke;
	cursor: pointer;
}
.singlebutton3 {
	background-color: transparent;
	cursor: pointer;
}
.singlebutton3:hover {
	background-color: whitesmoke;
	cursor: pointer;
}
.singlebutton4 {
	background-color: transparent;
	cursor: pointer;
}
.singlebutton4:hover {
	background-color: whitesmoke;
	cursor: pointer;
}

.singlebutton5 {
	background-color: transparent;
	cursor: pointer;
}
.singlebutton5:hover {
	background-color: whitesmoke;
	cursor: pointer;
}

.logo {
	height: 2rem;
	width: 2rem;
	border-radius: 15%;
	margin-left: 0.5rem;
}

.buttonscont {
	height: 210px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.center {
	margin: 0;
	position: absolute;
	top: 69%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	height: 175px;
	width: 200px;
	display: block;
}
.previewtext {
	font-size: 14px;
	overflow-y: auto;
	height: 171px;
	overflow: auto;
	display: block;
}

.Column1 {
	height: 800px;
	overflow: auto;
}
label {
	cursor: pointer;
}
.dropheader {
	height: 32px;
}

.hovereffectswelcome {
	background-color: whitesmoke;
	height: 248px;
	width: 215px;
	border-radius: 4px;
	margin-left: -32px;
	display: none;
}
.hovereffectssingle {
	background-color: whitesmoke;
	height: 248px;
	width: 215px;
	border-radius: 4px;
	margin-left: -32px;
	display: none;
}
.hovereffectsyesno {
	background-color: whitesmoke;
	height: 248px;
	width: 215px;
	border-radius: 4px;
	margin-left: -32px;
	display: none;
}
.hovereffectsmultiple {
	background-color: whitesmoke;
	height: 248px;
	width: 215px;
	border-radius: 4px;
	margin-left: -32px;
	display: none;
}
.hovereffectspicture {
	background-color: whitesmoke;
	height: 248px;
	width: 215px;
	border-radius: 4px;
	margin-left: -32px;
	display: none;
}
.hovereffectsopinion {
	background-color: whitesmoke;
	height: 248px;
	width: 215px;
	border-radius: 4px;
	margin-left: -32px;
	display: none;
}
.hovereffectsrating {
	background-color: whitesmoke;
	height: 248px;
	width: 215px;
	border-radius: 4px;
	margin-left: -32px;
	display: none;
}

.hoverimagecard {
	height: 95px;
	width: 150px;
	border-radius: 6px;
	margin: 0;
	position: absolute;
	top: 29%;
	left: 41%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.hovertitle {
	margin: 0;
	position: absolute;
	top: 55%;
	left: 33%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 14px;
	font-weight: 500px;
}
.hovertitleyesno {
	margin: 0;
	position: absolute;
	top: 55%;
	left: 20%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 14px;
	font-weight: 500px;
}
.hoverpara {
	margin: 0;
	position: absolute;
	top: 71%;
	left: 46%;
	width: 170px;
	line-height: 18px;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 12px;
}

/* new container */
.welcomecontainer {
	padding-top: 25px;
	display: flex;
	background-color: whitesmoke;
	height: 112px;
	justify-content: space-between !important;
	border-radius: 6px;
}

.welcomecontainer .logo {
	height: 1.7rem;
	width: 1.8rem;
}

.welcomecontainer input {
	border: none !important;
	outline: none;
	background-color: transparent;
	width: 25rem;
	margin-left: -15px;
	display: block;
	position: sticky;
}
.lengthcounter {
	margin-top: 7px;
	margin-left: -3px;
	color: rgb(96, 235, 103);
	font-weight: 600;
	font-size: 13px;
}
.cont-icons {
	margin: 0;
	position: absolute;
	top: 11%;
	left: 91%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	cursor: pointer;
	color: grey;
}

/* sinle choice container */
.singlecontainer {
	padding-top: 25px;
	display: flex;
	background-color: whitesmoke;
	height: 112px;
	justify-content: space-between !important;
	border-radius: 6px;
}

.singlecontainer input {
	border: none !important;
	outline: none;
	background-color: transparent;
	/* width: 25rem;
	margin-left: -15px;
	display: block;
	position: sticky; */
}

/* .card:hover{
	background-color: whitesmoke;
}
.card-body:hover{
	background-color: whitesmoke;
}

.card-footer:hover{
	background-color: whitesmoke;
} */
.iconscont {
	/* margin-left: 90%; */
	cursor: pointer;
	color: grey;
	display: flex;
    justify-content: flex-end;
	align-items: center;
    padding-right: 5%;
}
.iconscont1 {
	cursor: pointer;
	color: grey;
	display: flex;
	align-items: center;
    justify-content: flex-end;
    padding-right: 2%;
}

.multipleinput {
	width: 100%;
	border: none;
	outline: none;
	margin-top: 3px;
}
.hyphencss {
	font-size: 17px;
	display: flex;
	align-items: center;
	margin-top: 5px;
	margin-left: 20px;
}

.logomultiple {
	height: 1.9rem;
	width: 2rem;
	border-radius: 20%;
}
.card-footer {
	border-top: white !important;
	padding-top: 0rem !important;
	padding-bottom: 0.8rem !important;
}
.imageinputcont {
	height: 1.5rem;
	width: 3rem;
	border: 0.5px solid rgb(160, 6, 160);
	border-radius: 4px;
	display: flex;
	cursor: pointer;
}
.logomultipleinput {
	display: block;
	height: -webkit-fill-available;
}

.previewchoicediv {
	border: 1px solid grey;
	width: -webkit-fill-available;
	height: 2rem;
	border-radius: 4px;
	margin-top: 10px;
}
.imagecardchoice {
	margin: 0;
	position: absolute;
	top: 34%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	height: 199px;
	width: 200px;
	border-radius: 8px;
	display: none;
}
.imagecardsingle {
	margin: 0;
	position: absolute;
	top: 42%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	height: 199px;
	width: -webkit-fill-available;
	border-radius: 8px;
}
.centerchoice {
	margin: 0;
	position: absolute;
	top: 65%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	height: 175px;
	width: 200px;
	display: block;
}
.centerchoiceYesno {
	margin: 0;
	position: absolute;
	top: 70%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	height: 175px;
	width: 200px;
	display: block;
}
.imagepicturechoice {
	margin: 0;
	position: absolute;
	top: 37%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	height: 199px;
	width: -webkit-fill-available;
	border-radius: 8px;
}
.centerpicturechoice {
	margin: 0;
	position: absolute;
	top: 58%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	height: 175px;
	width: 200px;
	display: block;
}
.centerchoice h6 {
	margin-left: 12px !important;
	margin-top: 5px;
}
.centerchoiceYesno h6 {
    margin-left: 12px !important;
    margin-top: 5px;
}

.imagechoice1 {
	height: 80px;
	margin-left: -25px;
	margin-top: 3px;
	border-radius: 4px;
	width: 115px;
	display: block;
	margin-right: 5px;
}
.imagechoice2 {
	height: 80px;
	margin-left: 10px;
	margin-right: -20px;
	margin-top: 3px;
	border-radius: 4px;
	width: 115px;
	display: block;
}
.choicecaption1 {
	font-size: 14px;
	margin-bottom: 8px;
	margin-left: -20px !important;
	margin-top: 2px;
}
.choicecaption2 {
	font-size: 14px;
	margin-bottom: 8px;
	margin-left: 14px;
	margin-top: 2px;
}
.opinioncont {
	border: 0.5px solid rgb(117, 117, 117);
	border-radius: 3px;
	cursor: pointer;
	height: 40px;
	font-size: 24px;
}
.opinioncont:hover {
	background-color: #3d90ab;
	color: white;
}
.opinioncontrow {
	border: 0.5px solid rgb(117, 117, 117);
	border-radius: 3px;
	cursor: pointer;
	height: 40px;
	font-size: 24px;
	margin-top: 20px;
}

.ratingstar {
	display: block;
	margin-left: -40px;
	margin-right: -40px;
	position: absolute;
	top: 58%;
	left: 16%;
}

.timercont {
	background-color: #eaf5fa;
	border-radius: 2px;
	height: 3rem;
	width: 90%;
	margin-left: 5%;
	margin-top: 2%;
	border-radius: 6px;
}
.progresscont {
	padding-top: 6%;
	width: 95%;
	padding-left: 6%;
}
.emojiimg {
	height: 4rem;
	position: absolute;
	top: 66%;
	left: 39%;
}
.emojiTitle {
	position: absolute;
	top: 78%;
	left: 36%;
	font-size: 20px;
}
.radiobtn {
	height: 1rem;
	width: 2rem;
	opacity: 1 !important;
	transform: translate(-23px, 0px);
}
/* .radio {
	margin-left: -65%;
	margin-top: 30%;
}
.checkbox {
	margin-left: -65%;
	margin-top: 30%;
} */
.publishbtn {
	width: 30%;
	margin-left: 70%;
}

.imgContslider {
	display: block;
	height: 130px;
	overflow: hidden;
}
.imgSlider {
	height: 130px;
	width: 100%;
	display: inline-block;
	overflow: hidden;
}
.previewInput {
	display: inline-block;
	height: 26px;
	width: 90px;
	transform: translateY(-37px);
	z-index: 2;
	border: 1px solid grey;
}
.deleteicon {
	display: inline-block;
	transform: translate(-83px, -125px);
	z-index: 2;
}
.deleteicon:hover {
	transform: translate(-81px, -125px);
}

/* Quiz form image insert */
.addimage {
	transform: translate(-4px, -15px);
	opacity: 0;
	width: 23px;
}
.otpbox{
	margin-left: 2%;
	margin-right: 2%;
}
.publishcont{
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.clonebtn{
	border: none;
    background-color: transparent;
	cursor: copy;
	outline: none;
}
.clonebtn:focus {
	outline: none;
  }