.sms-table>thead>tr>th,.sms-table>tbody>tr>td{
    border : 1px solid #dee2e6 !important;
    padding: 0.75rem 0.75rem !important;
}

.sms-table>tbody>tr>td input:focus,.sms-table>tbody>tr>td input{
border: 1px solid #c9c9c9 !important;
outline-color: rgb(203, 197, 197) !important ;
border-radius:4px !important ;
padding: 0.4rem !important;

}