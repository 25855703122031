.tblHos{
    table{
        thead{
            tr{
                th{
                    padding: 0.75rem !important;
                }
            }
        }
        tbody{
            tr{
                td{
                    padding: 0.75rem !important;
                }
            }
        }
    }
}