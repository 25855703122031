.affiliate_program_styles {
  .info_nav {
      padding: 10px;
      border-radius: 5px;
      font-weight: 500;
      margin-block: 6px;
      cursor: pointer;
      transition: all ease .3s;
      user-select: none;

      &:hover {
          background-color: #e5e5e5;
      }
  }

  .activeClass {
      background-color: #e5e5e5;

  }

  .referral_details_header {
      font-size: 15px;
      color: #000;
      font-weight: 500;
  }

  .affiliate_tabs_section {
      flex-basis: 20%;
  }

  .affiliate_contents_section {
      flex-basis: 100%;

  }

  @media (max-width:992px) {
      .affiliate_tabs_section {
          flex-basis: 100%;
      }

      .affiliate_contents_section {
          flex-basis: 100%;

      }
  }

  .table {
      thead {
          th {
              background: #6c757d;
              color: white;
              border: 1px solid #dee3e6;
          }
      }

      tbody {

          tr:nth-child(even) {
              // background-color: #0000000d;
          }

          td,
          th {
              border: 1px solid #dee3e6;
          }

          td {
              height: 50px;
          }

          .mdi_icon_style {
              cursor: pointer;
          }

          .company_name {
              vertical-align: middle;
              // background-color: white !important;
          }
      }
  }

  .no_stores_msg_container {
      margin-top: 80px;
      text-align: center;

      h4 {
          font-size: 16px;
      }

      p {
          // font-size: 15px;

          a {
              text-decoration: underline;

              &:hover {
                  text-decoration: none;
              }
          }
      }
  }
}


.aff_store_item {
  display: flex;
  flex-direction: column;






  .aff_store_item_header,
  .aff_store_level1_header,
  .aff_store_level2_header,
  .aff_store_level3_header {
      background-color: #327ebc;
      border: 1px solid #f1f1f1f1;
      border-top: none;
      padding: 1rem 2rem;

      h5 {
          color: white;
          font-weight: 500;
      }







      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .aff_store_level2_header,
  .aff_store_level3_header {
      background-color: white;
      padding: 1rem 3rem;

      h5 {
          color: black;
          font-weight: 500;
      }

  }

  .aff_store_level1_header {
      background-color: #ffa6dd5c;
      padding: 1rem 2.5rem;

      h5 {
          color: black;
          font-weight: 500;
      }
  }

  button {
      margin-bottom: 0 !important;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: none;
      cursor: pointer;
  }

  svg {
      width: 14px;
      height: 14px;
  }
}

.store-modal {
  .modal-title {
      margin: 0;
      width: 100%;
  }

  .modal-header {
      padding: .75rem .25rem !important;
  }

  .modal-body {
      h6 {
          text-align: start;
          margin-top: .7rem;
          margin-bottom: .7rem;
          font-size: 1.1rem;
          font-weight: 500;
          color: black;

          &:last-child {
              margin-bottom: 0.4rem;
          }
      }

      span {
          font-weight: 500;
          color: rgb(86, 86, 86);

      }

  }
}



.referral_company_block {
  display: flex;
  gap: 1rem;
  //    flex-wrap: wrap;

  overflow-x: auto;
  max-width: 100% !important;
  width: 100%;

  button {

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    //   padding: .7rem .7rem;
    min-width: 20%;
      border-radius: 3px;

      @media screen and (max-width:767px) {
        //   min-width: 50%;
      }

      span:first-child {
        //   margin-bottom: .3rem;
      }





      border: 1px solid rgb(181, 181, 181);
      background: white;
  }

  .all_btn {
      flex-basis: auto;
      min-width: 6rem;
      width: 6rem;
      padding: 0rem .5rem;


  }

  button.active {

      background-color: #327ebc;
      color: white;
      border-color: #327ebc;

  }

  .direction_left,
  .direction_right {
      background-color: #327ebc;
      cursor: pointer;

      border-radius: 50%;

      svg {
          color: white;
          display: flex;
          width: 100%;
      }
  }
}

.referral_company_block::-webkit-scrollbar,.stores-div::-webkit-scrollbar {
    display: none;
}


@media screen and (min-width:'768px') {
  .referral_table {
      tbody {
          tr>td:first-child {
              width: 5% !important;
          }
  
          tr>td:nth-child(2) {
              width: 12% !important;
          }
  
          tr>td:nth-child(3) {
              width: 29% !important;
          }
  
          tr>td:nth-child(4) {
              width: 14% !important;
          }
  
          tr>td:nth-child(5) {
              width: 14% !important;
          }
  
          tr>td:last-child {
              width: 26% !important;
          }
      }
  }  
}

.selectTier{
    width: 400px !important;
    &>div{
        padding: .5rem !important;
        padding-right: 32px !important;
        font-size: .8rem;
        border:none !important;
    }
    &>fieldset{
        border: none !important;
    }
}

body:has(.selectTier)>div.MuiPopover-root 
{
    ul{
        padding:  0rem;
        padding-bottom: .4rem;
        li{
            margin-top: .4rem;
        }
        
    }
}

.update_tier_btn{
    cursor: pointer;

    &:focus{
        border: none;
        outline: none;
    }
}

.generate_invoice:active,.generate_invoice:focus{
    background: transparent !important;
    color: #1ea6ec !important;
    border-color: #1ea6ec !important;
    outline: none;
}