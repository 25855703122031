.main_div {

    .table>tbody>tr>td {
        padding: 1rem !important;
        vertical-align: top !important;
    }
}

.fevicon_dropzone {

    .font_style {
        font-size: 10px !important;
    }

}