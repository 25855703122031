/**=====================
    69. Dashboard Project CSS Start
==========================**/
.projects-main {
  .xm-mb-peity {
    .ct-chart {
      width: 435px !important;
      height: 56px;

      svg {
        .ct-series-a {
          stroke: #4466f2;

          .ct-bar {
            fill: none;
            stroke-width: 10px;
          }
        }

        .ct-series-b {
          stroke: #2ca6ec;

          .ct-bar {
            fill: none;
            stroke-width: 10px;
          }
        }

        .ct-series-c {
          stroke: #31ad47;

          .ct-bar {
            fill: none;
            stroke-width: 10px;
          }
        }
      }
    }
  }
}

.project-small {

  .ct-point,
  .ct-line,
  .ct-bar,
  .ct-slice-donut,
  .ct-slice-pie,
  .ct-slice-donut-solid,
  .ct-area {
    stroke: none;
  }

  .ct-area {
    fill-opacity: 1;
  }
}

.project-small-chart-1,
.project-small-chart-2,
.project-small-chart-3,
.project-small-chart-4,
.project-small-chart-5,
.project-small-chart-6 {
  svg {
    .ct-series-a {
      .ct-area {
        fill: url(#gradient1) !important;
      }
    }
  }
}

.chart-block {
  .flot-chart-container {
    &.budget-chart {
      height: 412px;
    }
  }
}

.theme-tab {
  .tab-title {
    display: flex;
    margin-bottom: 30px;
    border-bottom: 1px solid $horizontal-border-color;
    flex-wrap: wrap;

    li {
      display: inline-block;
      padding: 10px 25px;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      font-size: 16px;
      cursor: pointer;

      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        stroke-width: 3px;
      }

      &.react-tabs__tab--selected {
        background-color: $white;
        border-bottom: 2px solid $primary-color ;
        color: $primary-color ;
      }
    }
  }

  .tab-content-cls {
    .react-tabs__tab-panel--selected {
      padding: 0;
    }
  }
}

.tabs-project {
  .nav-item {
    .nav-link {
      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        stroke-width: 3px;
      }
    }
  }
}

.select2-drpdwn-project {
  select {
    padding-top: 0;
    border-bottom: 1px solid $primary-color !important;
    border: none;
    border-radius: 0 !important;
    height: 24px !important;
    padding-bottom: 0;
    background-color: $transparent-color;
  }
}

.project-widgets {
  margin-top: 30px;

  h1 {
    line-height: 0.8;
    font-size: 70px;
  }
}

.project-footer {
  h6 {
    color: $light-text;
  }
}

.project-charts {
  canvas {
    height: 265px !important;
    width: auto !important;
  }
}

.schedule {
  .media {
    align-items: center;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .media-body {
      h6 {
        margin-bottom: 3px;
        font-weight: 600;
      }

      p {
        color: $light-text;
      }
    }

    .schedule-dropdown {
      .dropdown-toggle {
        padding: 0;
        background: $transparent-color;

        &:after {
          display: none;
        }
      }

      .dropdown {
        position: absolute;
        top: 30px !important;
        right: 0 !important;
        min-width: auto;
        padding: .5rem 0;
        border-radius: 4px;
        border: 1px solid rgba($black, 0.15);
        background-color: $white;

        ul {
          li {
            padding: .25rem 1.5rem;
          }
        }

        a {
          font-size: 14px;
        }
      }
    }

    svg {
      circle {
        color: $light-text;
      }
    }
  }
}

.more-projects {
  div[class^="col-"] {

    &:last-child,
    &:nth-child(5) {
      .projects-main {
        margin-bottom: 0;
      }
    }
  }
}

.projects-main {
  margin-bottom: 30px;

  .project-content {
    margin-bottom: 10px;
  }

  h6 {
    color: $light-text;
  }

  .project-small {
    .ct-chart {
      height: 85px;

      svg {
        .ct-series-a {

          .ct-point,
          .ct-line,
          .ct-bar,
          .ct-slice-donut {
            stroke: none;
          }

          .ct-line {
            fill: transparent;
          }
        }
      }
    }
  }
}

.current-progress {
  table {
    tbody {
      tr {
        td {
          vertical-align: middle;
          padding-bottom: 20px;

          &:last-child {
            width: 460px;
          }

          .d-inline-block {
            >.d-inline-block {
              padding-top: 5px;

              h6 {
                margin-bottom: 2px;
                font-weight: 600;
              }
            }

            span {
              &:first-child {
                border-right: 2px solid $light-text;
                padding-right: 20px;
              }
            }

            .ml-current {
              padding-left: 20px;
              color: $light-text;

              i {
                margin-right: 20px;
              }
            }
          }
        }

        &:first-child {
          td {
            padding-top: 0;
          }
        }

        &:last-child {
          td {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.spent {
  h6 {
    color: $light-text;
    margin-bottom: 0;
  }

  .spent-graph {
    h1 {
      span {
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    .projects-main {
      .project-small {
        margin-top: -13px;
        width: 500px;
      }
    }

    .project-budget {
      margin-right: 30px;
    }
  }
}

.project-header {
  h5 {
    padding-top: 3px;
  }
}

.team-members {
  table {
    tbody {
      tr {
        td {
          padding-top: 15px;
          padding-bottom: 15px;

          div {
            div {
              p {
                margin-top: 14px;
              }
            }
          }
        }
      }
    }

    thead {
      tr {
        th {
          padding-bottom: 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1660px) {
  .current-progress {
    table {
      tbody {
        tr {
          &:first-child {
            td {
              min-width: 260px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .project-col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .chart-block {
    .flot-chart-container {
      &.budget-chart {
        height: 533px;
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .chart-block {
    .flot-chart-container {
      &.budget-chart {
        height: auto;

        canvas {
          width: 824px !important;
          height: auto !important;
          margin: 0 auto;
        }
      }
    }
  }

  .github-chart {
    canvas {
      height: 487px !important;
      width: auto !important;
    }
  }

  .project-widgets {
    h1 {
      font-size: 45px;
    }
  }

  .current-progress {
    table {
      tbody {
        tr {
          &:first-child {
            td {
              min-width: 323px;
            }
          }

          td {
            &:last-child {
              width: 115px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .chart-block {
    .flot-chart-container {
      &.budget-chart {
        canvas {
          width: 638px !important;
        }
      }
    }
  }

  .github-chart {
    canvas {
      height: 372px !important;
    }
  }

  .github-lg {
    margin-top: 30px;
  }

  .team-members {
    table {
      tbody {
        tr {
          td {
            min-width: 200px !important;
          }
        }
      }
    }
  }

  .projects-main {
    .xm-mb-peity {
      svg {
        width: 435px !important;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .spent {
    .spent-graph {
      .project-budget {
        margin-right: 20px;
      }
    }
  }

  .projects-main {
    margin-bottom: 20px;
  }

  .project-widgets {
    margin-top: 20px;
  }

  .github-lg {
    margin-top: 20px;
  }

  .schedule {
    .media {
      margin-bottom: 20px;
    }
  }

  .team-members {
    table {
      tbody {
        tr {
          td {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }

      thead {
        tr {
          th {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .github-chart {
    canvas {
      height: 289px !important;
    }
  }

  .projects-main {
    .xm-mb-peity {
      svg {
        width: 358px !important;
      }
    }
  }

  .chart-block {
    .flot-chart-container {
      &.budget-chart {
        canvas {
          width: 496px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .theme-tab {
    .tab-title {
      display: block;
    }
  }

  .github-chart {
    canvas {
      height: 214px !important;
    }
  }

  .chart-block {
    .flot-chart-container {
      &.budget-chart {
        canvas {
          width: 370px !important;
        }
      }
    }
  }

  .more-projects {
    div[class^="col-"] {
      &:nth-child(5) {
        .projects-main {
          margin-bottom: 15px;
        }
      }
    }
  }

  .theme-tab {
    .tab-title {
      li {
        display: block;
        text-align: center;
      }
    }
  }

  .spent {
    .spent-graph {
      .project-budget {
        margin-right: 0;
        margin-bottom: 15px;
      }

      .projects-main {
        .xm-mb-peity {
          margin-bottom: -6px;
        }
      }

      .d-flex {
        display: inherit !important;
      }
    }
  }

  .projects-main {
    margin-bottom: 15px;

    .project-content {
      margin-bottom: 0;
    }
  }

  .project-widgets {
    margin-top: 15px;
  }

  .github-lg {
    margin-top: 15px;
  }

  .schedule {
    .media {
      margin-bottom: 15px;
    }
  }

  .project-header {
    .select2-drpdwn-project {
      width: 50%;
      margin: 15px auto 0 auto;
    }
  }
}

@media only screen and (max-width: 360px) {
  .projects-main {
    .xm-mb-peity {
      .ct-chart {
        svg {
          .ct-bar {
            stroke-width: 6px !important;
          }
        }
      }
    }
  }

  .github-chart {
    canvas {
      height: 152px !important;
    }
  }

  .chart-block {
    .flot-chart-container {
      &.budget-chart {
        canvas {
          width: 271px !important;
        }
      }
    }
  }

  .projects-main {
    .xm-mb-peity {
      svg {
        width: 250px !important;
      }
    }
  }
}

/**=====================
     69. Dashboard Project CSS End
==========================**/