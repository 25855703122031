.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip-box {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #349BEE;
    color: #fff;
    padding: 0.6rem 0.6rem;
    /* border-radius: 4px; */
    white-space: nowrap;
    z-index: 10;
    opacity: 0.8;
    width: 100%;
    display: flex;
}

.tooltip-box::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 85%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #349BEE transparent;
}

.scale_template {
    transform: scale(0.25);
    margin-top: 0rem;
}