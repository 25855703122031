body {
    font-family: "Roboto", sans-serif !important;
}

.table>tbody>tr>td,
.table>tbody>tr>th {
    padding: 0rem 0.3rem !important;
    border: none;
    font-size: 12px;
}

.table>tbody>tr>.cust_table_data {
    padding: 8px !important;
}

.table>thead>tr>td {
    padding: 0rem 0.3rem !important;
    border: none;
    font-size: 12px;
}





.table>thead>tr>th {
    padding: 0.3rem 0.3rem !important;
    border: none;
    font-size: 12px;
}

.table>tbody>tr {
    padding: 0rem !important;
    font-size: 12px !important;
}

.table>thead>tr {
    padding: 0.20rem 0 !important;
    font-size: 0.62rem !important;
}

.table>thead>tr>th p {
    margin: 0 !important;
    font-size: 12px !important
}

.table>tbody>tr>td p {
    margin: 0 !important;
    font-size: 12px !important
}

.function_bookings_table>tbody>tr>td {
    padding: 0.3rem 0.3rem !important;
    border: none;
    font-size: 12px;
}

.function_bookings_table .inv_btn
{
    background-color: rgb(189, 43, 17);
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding: .4rem .8rem;
}


.function_bookings_table .inv_btn:hover{
color: white !important;
}

.invoice-modal .modal-content,.invoice-modal .modal-content>div{
    border: none !important;
    border-radius: 6px;

}

.icon-wrapper {
    position: absolute;
    bottom: 12%;
    right: 18%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0 0 6px 3px rgba(68, 102, 242, .1);
    margin: 0 auto;
    font-size: 17px;
    text-align: center;

}

.edit-icon_wrapper {
    position: absolute;
    bottom: 0%;
    right: 0%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0 0 6px 3px rgba(68, 102, 242, .1);
    margin: 0 auto;
    font-size: 17px;
    text-align: center;

}

.edit-icon_wrapper_merc {
    position: absolute;
    bottom: 0%;
    right: 0%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0 0 6px 3px rgba(68, 102, 242, .1);
    margin: 0 auto;
    font-size: 17px;
    text-align: center;

}

.icon-wrapper:hover {
    cursor: pointer;
}

.icofont-pencil-alt-5 {
    height: 25px;
    width: 25px;
    margin-top: 10px;
}

.pencil {
    position: absolute;
    left: 0;
    right: 0;
    line-height: 3;
    opacity: 0;
}


/* Chrome, Safari, Edge, Opera */
.number::-webkit-outer-spin-button,
.number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.number {
    -moz-appearance: textfield;
}

.btn:disabled {
    cursor: not-allowed;
}

.search-filter {
    background-color: #E3E8EE;
    padding: 5px 8px;
    border-radius: 6px;
    margin-right: 8px;
    color: black;
}

.search-filter__line {
    padding: 9px 10px;

}

.search-filter__line:hover {
    background-color: #327ebc;
    color: white;
    cursor: pointer;
}

.search-filter__active {
    background-color: #327ebc;
    color: white;
}

.btn-outline-primary:disabled {
    color: #327ebc;
}

.authorize {
    color: goldenrod;
    box-shadow: 0px 3px 12px rgba(119, 196, 229, 0.6);
    font-size: 0.9rem;
    text-align: center;
    cursor: pointer;
}

.tab-title {
    margin-bottom: 0px !important;
    padding-left: 1rem
}

/* .react-tabs__tab--selected,
.react-tabs__tab {
    padding: 10px 15px !important;
    font-size: 0.75rem !important;
} */

.react-tabs__tab--selected {
    background: none !important;
}


.subTab {
    color: gray;
}

.col-sm-12 {
    padding: 0 !important;
}

.btn-light,
.btn-light:disabled {
    padding: 6px 13px;
    font-size: 0.63rem;
    border-left: 1.5px solid rgb(197, 189, 189) !important;
    border-right: 1.5px solid rgb(197, 189, 189) !important;
    border-radius: 0;
}

.page-header {
    padding: 15px 0 !important;
}

.checkbox_animated::before,
.checkbox_animated::after {
    width: 12px;
    height: 12px
}

.billing-form label,
.billing-form input,
.billing-form select,
.table textarea {
    font-size: 0.75rem !important;
}

.billing-form select {
    height: 2rem !important;
}

.nav-light .nav-tabs.nav-item .nav-link.active {
    color: #327ebc !important;
    font-size: 0.75rem !important;
}

.nav-light .nav-tabs.nav-item .nav-link {
    color: black !important;
    font-size: 0.75rem !important;
}

.nav-link {
    padding: 3px 12px !important
}

.page-wrapper .page-main-header {
    height: 65px !important;
    width: calc(100% - 230px) !important;
    margin-left: 240px !important;
}

.page-wrapper .page-body-wrapper .sidebar {
    box-shadow: none;
}

.page-wrapper .page-body-wrapper .page-sidebar {
    width: 210px !important;

}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header,
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li>a {
    font-weight: 400 !important;
    font-size: 0.8rem !important;
}

.page-wrapper .page-body-wrapper .page-sidebar .main-header-left {
    height: 65px !important;
    box-shadow: none;
    border-bottom: 1px solid rgb(249, 247, 247);

}

.page-wrapper .page-body-wrapper .page-sidebar~.page-body {
    margin-left: 210px !important;
}

.page-wrapper .page-body-wrapper .page-body {
    margin-top: 65px !important;
}

.search-form input {
    border: none !important;
    background-color: white !important;
}

.fileContainer .uploadPictureContainer {
    width: 80% !important;
}

.page-link {
    min-width: 0 !important;
    padding: 8px;
}

.react_times_button .wrapper {
    transform: translateY(0);
    height: 100%;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.preview_container {
    height: 30px !important;
    line-height: 30px !important;

}

.preview_container svg {
    top: 1px !important;
}

.time_picker_preview {
    height: 40px !important;
}

.react_times_button {
    box-shadow: none !important;
}

.more-button-custom {
    background-color: unset !important;
}

.qrpage-links {
    border-right: 1px solid rgba(173, 176, 178, 0.2);
}

.my-qr__qr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 3px solid rgba(173, 176, 178, 0.2);
    border-radius: 10px;
    margin: 1rem 1rem;
    box-sizing: border-box;
}

.qr-details__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.7rem;

}

.qr-details__name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: 1px solid #f2f4f7;
    border-radius: 3px;
}

.editQr-modal_input {
    border: 1px solid #f2f4f7;
    color: #646777;
    width: 100%;
    padding: 7px 10px;
    font-size: 0.75rem;
    background: white;
}

.qr-page__link-setting {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1.2rem;
    text-align: left;
}

.qrpage-btn {
    width: 30%;
    padding: 0.4rem 0.7rem;
    border-radius: none;
}

.qrpage-btn-group {
    margin-top: 30px !important;
    margin-left: 5%;
}

.qr-page__poster {
    max-height: 70%;
    width: 100%;

}

.qr-page__preview {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 70%;
    top: 16%;
    left: 0%;
    font-weight: bold;
    text-align: center;
    color: white !important;
    line-height: 7;
    cursor: pointer;

}

.custom-input {
    border: none;
    border-bottom: 1px solid rgb(197, 189, 189);
    border-radius: 0;
    margin: 0px 10px 6px;
    font-size: 0.75rem
}

.date-picker:focus {
    outline-color: black;
}

@media (min-width : 260px) and (max-width : 990px) {
    .qrpage-btn-group {
        margin-top: 10px;
    }

    .qrpage-links {
        border-right: none;
    }
}

@media (min-width : 991px) {
    .qrpage-btn-group {
        margin-top: 53px !important;
    }
}

.qrmodal-button {
    border: none;
    margin: 0;
    padding: 0.7rem;
    font-size: 0.9rem;
}


.printers>tbody>tr>td {
    font-size: 0.8rem;
}

.printers>thead>tr>th {
    font-size: 0.8rem;
}

.alert {
    padding-top: 5px;
    padding-bottom: 5px;
}

.searchIcon input:focus {
    outline: none;
}

.theme-tab .tab-title li {
    border-radius: 0.15rem;
    border: 1px solid #dfe8f1;
    margin-right: 10px;
    margin-bottom: 0.5rem;
    font-size: 12px;
    margin: 10px 5px;
    padding: 10px 12px;
}

.theme-tab .tab-title li.react-tabs__tab--selected {
    background-color: #327ebc !important;
    border: 1px solid #327ebc;
    color: #ffff;
}

.table-border-horizontal tr,
.table-border-horizontal th,
.table-border-horizontal td {
    border: 1px solid #dee2e6 !important;
    padding: 0.75rem 0.75rem;
}

.table-border-horizontal a {
    color: #327ebc;
    font-size: 12px;
}

.nav-light .nav-tabs.nav-item .nav-link.active {
    background-color: #f6f7fb;
    color: #327ebc !important;
    display: flex;
    align-items: center;
}

.tblwithdrawP {
    font-weight: 900;
    font-size: 13px !important;
    color: #4b5056;
    font-family: "Open Sans",
        "Helvetica Neue",
        Helvetica,
        Arial,
        sans-serif;
    line-height: 1.42857143;
}

.tblwithdrawPTop {
    padding-top: 12px;
}

.tblwithdrawPBTM {
    padding-bottom: 12px;
}

.btn-outline-primary {
    border-color: #327ebc;
    color: #327ebc;
    background-color: transparent;
}

.btn-primary {
    background-color: #327ebc !important;
    border-color: #327ebc !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active {
    color: white;
    background-color: #327ebc !important;
    border-color: #327ebc !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    background-color: #327ebc !important;
    border-color: #327ebc !important;
}

.nav-primary .nav-tabs.nav-item .nav-link.active {
    background-color: #327ebc;
    color: #fff !important;
}

.nav-primary .nav-tabs.nav-item .nav-link {
    color: #327ebc !important;
}

.page-main-header .main-header-right .nav-right ul li .dot {
    width: 8px;
    height: 8px;
    border-radius: 30px;
    background-color: #ff9f40;
    position: absolute;
    right: 12px;
    bottom: 6px;
    animation: blink 1.5s infinite;
    top: 10px;
}

.tooltip {
    pointer-events: none;
}