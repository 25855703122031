.addCustomerForm {

    .input_style {
       height: 34px !important;
       font-size: 13px;
       border: 1px solid rgb(211, 213, 216) !important;
    }
 
    .mob_input_style {
       height: 34px !important;
       border: 1px solid rgb(211, 213, 216) !important;
    }
 
    .form__form-group-error {
       margin-bottom: -10px !important;
    }
 
    // .error_message{
    //    margin-bottom: -10px !important;
    // }
 
    .react-datepicker-wrapper {
       width: -webkit-fill-available;
    }
 
 }
 
 .modalDark {
    background-color: #181A1E !important;
 
    p,
    h5,
    h6,
    h1,
    h2,
    h3,
    h4,
    a,
    label,
    svg {
       color: #f8f8f8 !important;
    }
 }
 
 ::placeholder {
    font-size: 12px !important;
 }
 
 @media (max-width: 480px) {
    ::placeholder {
       font-size: 18px !important;
    }
 }
 
 .addCustomerPopup input,
 .addCustomerPopup input::placeholder,
 .addCustomerPopup label,
 .addCustomerPopup .react-select__control {
    font-size: 15px !important;
 
 }
 
 .addCustomerPopup input {
    border: 1px solid rgb(211, 213, 216);
    border-radius: 0.3rem;
    padding-top: 1.6rem;
    // padding-bottom: 1.6rem !important;
 }
 
 .addCustomerPopup input[name="mobile"] {
    border-top-left-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
    -webkit-border-top-left-radius: 0rem !important;
    -moz-border-bottom-left-radius: 0rem !important;
    -webkit-border-top-left-radius: 0rem !important;
    -moz-border-bottom-left-radius: 0rem !important;
 
    // border-left: none !important;
 }
 
 // .addCustomerPopup .react-select__control {
 //    // height: 43px !important;
 //    border: 1px solid rgb(211, 213, 216) !important;
 //    border-radius: 0.3rem !important;
 //    border-top-right-radius: 0rem !important;
 //    border-bottom-right-radius: 0rem !important;
 //    -webkit-border-top-right-radius: 0rem !important;
 //    -moz-border-bottom-right-radius: 0rem !important;
 //    -webkit-border-top-right-radius: 0rem !important;
 //    -moz-border-bottom-right-radius: 0rem !important;
 //    border-width: 1px 0px 1px 1px !important;
 // }
 
 .addCustomerPopup .react-select__control .react-select__single-value {
    font-size: 15px !important;
    color: #646777 !important;
 }
 
 .addCustomerPopup .react-select__control .react-select__value-container>span>span {
    height: 25px !important;
 }
 
 .custom-sidemodal .sms_modal_input {
    height: 43px !important;
    padding: 5px 8px !important;
 }
 
 input.sms_modal_input::-webkit-outer-spin-button,
 input.sms_modal_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
 }
 
 input[type=number].sms_modal_input {
    -moz-appearance: textfield;
 }
 
 .select-main-phone input {
    padding: 0 !important;
 }
 
 .select-main-phone .react-select__input input {
    width: 10px !important;
    overflow: hidden;
    white-space: nowrap;
 }
 
 .select-main-phone .react-select__input {
    opacity: 0;
 }
 
 .select-country-signup .react-select__group {
    display: none;
 }
 
 .select-country-signup .react-select__group:has(.search-option-select) {
    display: block;
 }
 
 
 .select-country-signup input {
    outline: none !important;
    width: 100% !important;
    display: block;
    box-shadow: none !important;
    // position: absolute;
    z-index: 10;
    position: relative;
    padding: 0 !important;
    text-transform: capitalize;
    opacity: 0 !important;
    height: 32px !important;
 
 
 
 }
 
 .select-country-signup .react-select__input input:focus {
    box-shadow: none !important;
    border: none !important;
 
 
 }
 
 .selectDummyValue {
    position: absolute;
    color: #313131 !important;
    font-size: 13px;
    left: 7.5%;
 
    top: 57%;
 
    @media screen and (max-width:'767px') {
       color: #646777 !important;
       font-size: 16px !important;
       left: 10.5%;
 
       top: 53%;
 
 
 
    }
 
 }
 
 body:has(.select-country-signup .react-select__single-value) .selectDummyValue {
    display: none;
 }
 
 button.sendSMSButton-modal.disabled {
    background: #3092d7 !important;
 }
 
 .remove-focus-text-ar:focus-visible {
    border: 1px solid #dee2e6 !important;
    outline: none !important;
 }