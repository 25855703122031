.order_online_tab {
    .mob-font {
        color: #646777;
    }

    .checklist__checkmark.completed {
        background-color: #35B543;
        /* background-color: #45c7a2; */
    }

    .checklist__checkmark:hover {
        border: 1px solid rgb(69, 199, 162);
    }

    .checklist__checkmark {
        display: flex;
        justify-items: center;
        align-items: center;
        justify-content: center;

        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
        width: 30px;
        min-width: 30px;
        height: 30px;
        border: 1px solid rgba(125, 141, 158, 0.3);
        border-radius: 50%;
        color: #7d8d9e;
        transition: color 0.1s linear, border-color 0.1s linear, box-shadow 0.2s linear, background-color 0.1s linear;
    }

    .progress {
        height: 4px;
    }

    .progress-wrap.progress-wrap--small .progress-bar {
        height: 3px;
    }

    .progress-store-comp .progress-bar {
        background-color: rgb(53, 181, 67);
    }

    .progress-wrap .progress-bar {
        background-color: rgb(53, 181, 67);
        // color: #646777;
        color: rgb(69, 199, 162);
        box-shadow: none;
        height: 4px;
        font-size: 9px;
        line-height: 13px;
    }

    .border-bottom-overview {
        border-bottom: 1px solid rgb(213, 212, 213) !important;
    }

    .rounded-overview {
        border-radius: 1rem !important;
        overflow: hidden;
    }

    .border-overview {
        border: 1px solid rgb(213, 212, 213) !important;
    }
}