.dropzone__img {
    margin: 0;
    height: 100%;
    width: 100%;
    text-align: center;
}

.dropzone__img img {
    width: 100%;
}

/* input:active,
input:focus {
    border: none !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #fff !important;
} */