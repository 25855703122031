.custom-sidemodal{
    background-color: white;
    height: 70vh;
    width: 20vw;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1500;
    margin-top: -35vh;
    margin-left: -10vw;
   
}

.sidemodal__close{
    height: 15%;
    padding: 0.9rem 1.5rem;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sidemodal__body{
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
