.merchantInvoice {
    .invoice__logo {
        width: inherit;
        height: 16px;
        margin-bottom: 10px;
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: left;
        background-size: contain;
        background-image: url(https://demo.business.ipaypro.co/static/media/business_new-logo.df3c01d6.png);
    }

    .loaderDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }


    .invoice__grand-total {
        font-weight: 500;
        font-size: 20px;
        // margin-top: 15px;
        // margin-bottom: 30px;
    }
}