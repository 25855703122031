.view_transaction_page_stylings {
    .timeline {
        position: relative;
        margin-top: 10px;

        .timeline__item {
            position: relative;
            padding-bottom: 18px;
        }

        .timeline__item:not(:last-child):before {
            content: "";
            background-color: #dddddd;
            width: 1px;
            height: 100%;
            position: absolute;
            margin-top: 5px;
        }

        .timeline__icon {
            position: absolute;
            left: -5px;
            height: 10px;
            width: 10px;
            margin-top: 5px;
            border-radius: 5px;
            background-color: #D23F99;
            overflow: hidden;
            text-align: center;
            display: flex;
            background-color: #988e94;
        }

        .timeline__icon span {
            color: white;
            font-size: 20px;
            margin: auto;
        }

        .lnr {
            font-family: Linearicons-Free;
            // speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .timeline__content {
            width: calc(90% - 52px);
            margin-left: 10px;
            border-radius: 5px;
            position: relative;

            padding-right: 25px;
            padding-left: 10px;

            h4.subhead {
                font-size: 12px;
                color: #999999;
                line-height: 16px;
            }
        }

        .timeline__content:after {
            content: "";
            position: absolute;
            top: 20px;
            border: 10px solid transparent;
        }
    }



}