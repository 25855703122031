.dashboard_comp {

    .chart_box {
        height: 365px;
    }

    @media (min-width: 1920px) {
        .chart_box {
            height: auto !important;
        }
    }

    @media (min-width: 0px) and (max-width:768px) {
        .chart_box {
            height: 330px;
        }
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        .chart_box {
            height: 355px;
        }
    }

    @media (min-width: 1025px) and (max-width: 1366px) {
        .chart_box {
            height: 330px;
        }
    }

    @media (min-width: 1367px) and (max-width: 1920px) {
        .chart_box {
            height: 375px;
        }
    }

}