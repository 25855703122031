.toggle_style {

    text-align: center;

    /* .container {
        text-align: center;
    } */

    .toggle-switch {
        position: relative;
        width: 50px;
        display: inline-block;
        text-align: left;
        top: 0px;
    }

    .checkbox {
        display: none;
    }

    .label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid #bbb;
        border-radius: 20px;
    }

    .inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
    }

    .toggle-switch .label .inner:before,
    .toggle-switch .label .inner:after {
        float: left;
        width: 50%;
        height: 23px;
        padding: 0;
        line-height: 36px;
        color: #fff;
        font-weight: bold;
        box-sizing: border-box;
    }

    .toggle-switch .label .inner:before {
        content: "";
        padding-left: 10px;
        background-color: #D998B8;
        color: #fff;
    }

    .toggle-switch .label .inner:after {
        content: "";
        padding-right: 10px;
        background-color: #D998B8;
        color: #fff;
        text-align: right;
    }

    .switch {
        display: block;
        width: 23px;
        height: 23px;
        margin: 5px;
        background: #BF1E73;
        position: absolute;
        top: -5px;
        bottom: 0;
        right: 24px;
        border: 0 solid #bbb;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;
    }

    .checkbox:checked+.label .inner {
        margin-left: 0;
    }

    .checkbox:checked+.label .switch {
        right: -4px;
    }

    @media (max-width: 576px) {

        .toggle-switch {
            width: 60px !important;
        }

        .inner:before,
        .inner:after {
            height: 30px !important;
        }

        .switch {
            display: block;
            width: 28px;
            height: 28px;
            top: -4px;
        }

        .checkbox:checked+.label .switch {
            right: -3px;
        }

    }
}