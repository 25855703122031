.affiliate-tier-table{
   &>thead>tr>th,&>tbody>tr>td{
    padding: .4rem .5rem !important;
   }

   tr{
    // td:first-child{
    // width: 4% !important;
    // }
    td:nth-child(2){
    width: 20% !important;
    }
    td:nth-child(3){
    width: 21% !important;
    }
    td:nth-child(4){
    width: 25% !important;
    }
    td:last-child{
    width: 30% !important;
    .comment_status_info{
        margin-left: .2rem;
        border:1px solid rgb(213, 213, 213) !important;
        outline: none !important;
        padding: .2rem !important;
        border-radius: 4px;
        flex-grow: 1;
        &::placeholder{
            color: gray;
        }
    }
    }
   }

   tbody{
    tr:nth-child(odd)
    {
        td{
            background: #f6f6f6;
        }
    }
   }
}