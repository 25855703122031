.upperNumber {
	color: #4466f2;
	font-size: 26px;
}
.tabCont {
	text-align: center !important;
}
.tableheading {
	padding: 12px !important;
	/* border: 1px solid lightgrey; */
	font-size: 17px !important;
	color: #4466f2 !important;
}
.tablecell {
	font-size: 14px !important;
	font-weight: 500 !important;
}
