@media screen and (max-width:'767.98px') {
    .roles_table_header div {
        width: auto !important;
        padding: 0rem .5rem;
    }

    .roles_table_body div {
        width: 150px !important;
    }
    

    .create-role-modal {
        width: 100%;


    }
}
.roles_table_body div  {
    padding: 10px !important;
}