.list_result {

  position: absolute;
  top: 60px;
  width: 900px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border-radius: 10px;
  padding: 0rem !important;
  z-index: 1000;


  .result_list_section .info_bar {
    list-style: none !important;
    background-color: #327ebc !important;
    color: #fff !important;
    border-radius: 7px !important;
    margin: 10px !important;
    width: 100% !important;
    text-align: left !important;
    padding: 5px 10px !important;
  }

  .result_list_data_section {
    min-height: 100%;
    max-height: 60vh;
    overflow-y: auto;
  }

  .result_list_data_section li:hover {
    background-color: #c3dbef;
  }


}