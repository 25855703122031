.amount-div {
    cursor: pointer;
    height: 40px;
    justify-content: center;
    background-color: #fafbfe;
    border-radius: 2px;
    border-width: 1px;
    border: 1px solid #f2f2f2;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.amount-div.selected {
    background-color: #3487a1;
    color: white;
    border-radius: 5px;
}

.descDiv {
    border: 1px solid #d9dce4;
    height: 40px;
    margin-top: 1rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .dsc {
        border: none;
        position: absolute;
        left: 0;
        top: 10px;
    }

    .info-icon-div {
        margin-top: 0.5rem;
    }

    .dsc::placeholder {
        padding-left: 0.5rem;
        font-size: 1rem !important;
        color: #c1c2c4 !important;
    }
}

.linkPaySubmit {
    background-color: #3487a1 !important;
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    margin-left: 0rem !important;
    margin-top: 1rem !important;
}

.txt {
    font-size: 0.8rem;
    text-align: left !important;
    line-height: 1rem !important;
    font-weight: bold;
}

.footerCol {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
}

.paytext {
    font-size: 0.9rem;
}

.paylink {
    font-size: 0.9rem;
}

.fieldcol {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
    border: 1px solid rgb(232, 232, 160);
}

.fieldcoldate {
    padding-right: 0.5rem !important;
    padding-left: 0.1rem !important;
    border: 1px solid rgb(232, 232, 160);
    font-size: 0.8rem;
    padding-top: 0.2rem;
}

.fieldrow {
    margin-left: 0.1rem !important;
    margin-right: 0.1rem !important;
}

.fieldrowdate {
    margin-bottom: -0.3rem !important;
}

.datext {
    border-left: 1.5px solid rgb(232, 232, 160);
    margin-left: 1.7rem;
    font-size: 0.9rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.check {
    padding: 0.25rem;
    margin: 0.25rem;
}

.py-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
}

.py-4 {
    padding-top: 1.1rem !important;
    padding-bottom: 1.1rem !important;
}

.sex-select-container {
    width: 100%
}

.sex-select__value-container {
    height: 30px;
}

//store info Phone View
.store_info_cardCont {
    width: 100%;
    height: 100%;
    background: #111;
    color: #fff;
    max-width: 600px;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    // box-shadow: 0 0 15px 3px rgb(0 0 0 / 8%);
    position: relative;

    .scrollable {
        // height: calc(100% - 80px);
        // height: -webkit-calc(100% - 80px);
        overflow: auto;
        position: relative;
        z-index: 8;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            width: 0;
        }

        .cover {
            width: 100%;
            height: 100px;
            // height: 75px;
            background-color: #a1d9d1;
        }

        .stats-wrap {
            // float: right;
            // margin: 20px 0 40px;
            padding-right: 10px;

            .stats {
                font-size: 12px;
                display: inline-block;
                background: #fff;
                font-weight: 600;
                color: #000;
                padding: 4px 12px;
                border-radius: 5px;
                border: solid 2px #fff;
            }

            .share {
                cursor: pointer;
                // background: rgb(161, 217, 209);
                width: 28px;
                height: 28px;
                padding: 5px;
                border-radius: 100%;
                margin: 0 8px;
            }
        }

        .profile-pic {
            width: 100px;
            height: 100px;
            overflow: hidden;
            border-radius: 20px;
            margin: -40px 0 20px 20px;
            // margin: -50px 0 20px 20px;
            // background-color: #fff;
        }

        .name {
            display: inline-block;
            text-align: left;
            // font-size: 30px;
            font-size: 22px;
            font-weight: 600;
            line-height: 1.2em;
            margin: 0 auto 30px;
            // white-space: nowrap;
        }

        h5 {
            text-transform: uppercase;
            font-size: 14px;
            margin: 0 0 10px;
            // color: #fff;
            color: inherit;
            font-weight: 600;
        }

        .snapshots {
            margin-top: 15px;
            margin-bottom: 30px;

            .snapshot {
                // margin: 0 0 15px;
                margin: 0 0 6px;
                font-size: 11px;
                padding: 0;
                line-height: 1.3em;
                // color: #aaa;
                // color: #fff;
                color: inherit;
            }
        }

        .socials {
            margin: -5px 0 30px;

            .social {
                margin: 0 0 8px;
                // border-bottom: 1px solid hsla(0, 0%, 100%, .1);
                padding: 10px 0 15px;
                position: relative;
                font-size: 11px;

                span {
                    display: block;
                    width: calc(100% - 50px);
                }

                .social_item,
                a {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    // color: #fff;
                    color: inherit;
                    text-decoration: none;
                }
            }

        }

        .working_hours_container {
            padding-left: 8px;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            .working_hour {
                display: flex;
                position: relative;
                // color: white;
                color: inherit;

                p,
                span {
                    // color: #fff;
                    color: inherit;
                    font-size: 11px;
                    // width: 100px;
                }
            }
        }

        .credit {
            padding: 20px;
            text-align: center;
            margin: 50px auto 0;
            font-size: 11px;
            // color: rgba(255, 255, 255, 0.45);
            color: inherit;

            span {
                font-size: 11px;
            }

            .links {
                text-align: center;
                margin: 8px 0 0;

                a {
                    // color: #fff;
                    color: inherit;
                    font-size: 12px;
                    display: inline-block;
                    padding: 3px 10px;
                    text-decoration: none;
                }
            }
        }
    }

}

.portfolio_modal_styles {
    .custom_design_container {
        position: relative;
        overflow: hidden;
    }

    .custom_design_container::before {
        content: '';
        width: 8px;
        background: #999BAF;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0;
    }
}