.valid_form {
    .invalid_field {
        border: 1px solid red;
    }
    .valid_field {
        border: 1px solid green;
    }
    select.valid_field {
        border: 1px solid green;
    }
    .form-control, .btn {
        box-shadow: none!important;
        outline: none!important;
    }
    .errorMessage {
        font-size: 12px;
        color: red;
    }
    
}