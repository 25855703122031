.form-check {
    margin-right: 20px;
}

.checkbox label {
    padding-left: 10px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.dataCell {
    border: 1px solid #dee2e6;
    margin: 0px;
    border-collapse: collapse;
    display: flex;
    align-items: center;

}
.add-subscription .react-datepicker__input-container input{
    border : 1px solid #ced4da !important
}

.headerCell {
    border: 1px solid #dee2e6;
    background-color: #f9fafe;
    margin: 0px;
    border-collapse: collapse;
    display: flex;
    align-items: center;
    font-weight: 600;

}

.featDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.featureName {
    /* font-size: 14px; */
    font-size: 13px;
    /* font-weight: 600; */
    font-weight: 500;
}

.headerCell.col {
    font-size: 13px;
}

.dataCell.col {
    font-size: 13px;
}

.inputDiv {
    display: block;
}

.textAreaa {
    font-size: 12px !important;
    width: 100%;
}

/* .feaCell {
    padding: 10px;
} */
@media (min-width:1315px) {
    .subscriptions_header_tab_row .subscriptions_home_title {
        flex-basis: 37%;
    }

    .subscriptions_header_tab_row .subscriptions_tabs_section {
        flex-basis: 63%;
    }

}