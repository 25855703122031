/* .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {
  margin-left: 210px !important;
  margin-right: 10%;
} */
/* .page-body-above {
  margin-left: "210px";
} */

@import url(https://fonts.googleapis.com/css?family=Raleway:300);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

.page-main-header {
  background: linear-gradient(154deg, #008fe2 0, #00b29c 100%);
}

.page-wrapper .page-body-wrapper .page-sidebar .main-header-left {
  background: #008fe2;
}

.page-main-header .main-header-right svg line {
  color: #fff;
}

.page-main-header .main-header-right .nav-right ul li svg {
  color: #fff;
}

.page-main-header .main-header-right .nav-right>ul>li h6 {
  color: #fff;
}