.table_reservation_heading_block {

    .opposite {
        background-color: red !important;
    }

    input:checked+.opposite {
        background-color: #35B543 !important;
    }
}

// @media (max-width:480px) {

//     .table_reservation_heading_block {

//         .switch {
//             width: 65px;
//             height: 25px;
//             margin-top: 0px !important;
//         }

//         .slider:before {
//             height: 17px;
//             width: 17px;
//         }
//     }
// }